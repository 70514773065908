window.dashboardInit = function (state_data, offline_displays_count) {
    //State wise data

    // chart colors
    var colors = ['#007bff', '#1ECCB0'];
    var dougnutchart = {
        "responsive": true,
        "maintainAspectRatio": false,
        legend: {
            position: 'bottom',
            labels: {
                boxWidth: 10,
                padding: 12
            }
        },
        title: {
            display: true,
        }
    }

    var barColors = ['#06D6A0','#26547C'];
    //storage_chart

    var storage = document.getElementById('Storage').getContext('2d');
    var storageChart = new Chart(storage, {
        type: 'doughnut',
        data: {
            labels: ["Used", "Remaining"],
            datasets: [{
                backgroundColor: barColors,
                data: JSON.parse(storage.canvas.dataset.data),
            }]
        },
        options: dougnutchart
    });
    if (document.getElementById('display_lisence')) {
    var display_lisence = document.getElementById('display_lisence').getContext('2d');
        var display_lisenceChart = new Chart("display_lisence", {
        type: "doughnut",
        data: {
            labels: ["Used", "Remaining"],
            datasets: [{
                backgroundColor: barColors,
                data: JSON.parse(display_lisence.canvas.dataset.data),
            }]
        },
        options: dougnutchart
    });
    }

    if (document.getElementById('display_status')) {
        var display_status = document.getElementById('display_status').getContext('2d');
        var display_statusChart = new Chart("display_status", {
            type: "doughnut",
            data: {
                labels: ["Online ", "Offline "],
                datasets: [{
                    backgroundColor: barColors,
                    data: JSON.parse(display_status.canvas.dataset.data),
                }]
            },
            options: dougnutchart
        });
    }

    /* bar chart */
    if (document.getElementById("offline")) {
    var offline = document.getElementById("offline").getContext('2d');
        var xValues = ["<1Day", "1-3days", "3-5days", "5-7days", "7-15days", "15-30days", ">30 days"];
        if (offline) {
            var offlineChart = new Chart(offline, {
                type: 'bar',
                data: {
                    labels: xValues,
                    datasets: [{
                        data: offline_displays_count,
                        backgroundColor: colors[0],
                        barThickness: 20,
                    }]
                },
                options: {

                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: 'Days',
                                fontSize: 16,
                                fontColor: '#000',
                            },
                            barPercentage: 0.4,
                            categoryPercentage: 0.5,
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: 'Display Count',
                                fontSize: 16,
                                fontColor: '#000',
                            },
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                stepSize: 50,
                            }
                        }]
                    }
                }
            });
        }

    $("#offline").click(function (event) {
        const points = offlineChart.getElementsAtEventForMode(event, 'nearest', {intersect: true}, true);
        if (points.length > 0) {
            const stateName = offlineChart.data.labels[points[0]._index];

            if(stateName == "<1Day") {
                name = "offline_1_day";
            }
            else if(stateName == "1-3days") {
                name = "offline_3_day";
            }
            else if(stateName == "3-5days") {
                name = "offline_5_day";
            }
            else if(stateName == "5-7days") {
                name = "offline_7_day";
            }
            else if(stateName == "7-15days") {
                name = "offline_15_day";
            }
            else if(stateName == "15-30days") {
                name = "offline_30_day";
            }
            else {
                name = "offline_greater_30_day";
            }
            const url = `displays/status?online=${name}`; // Replace with the appropriate URL format for your Rails app
            window.location.href = url;
        }
    })
    }

    if (document.getElementById("app_version")) {
    var app_version = document.getElementById("app_version").getContext('2d');
        new Chart(app_version, {
            type: 'bar',
            data: {
                labels: JSON.parse(app_version.canvas.dataset.key),
                datasets: [{
                    data: JSON.parse(app_version.canvas.dataset.data),
                    backgroundColor: colors[0],
                    borderRadius: 15,
                    barThickness: 20
                }]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'App Version',
                            fontSize: 16,
                            fontColor: '#000',
                        },
                        barPercentage: 0.4,
                        categoryPercentage: 0.5
                    }], yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'Display Count',
                            fontSize: 16,
                            fontColor: '#000',
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 50
                        }
                    }]
                }
            }
        });
    }
    function addData(numData, chart){
        for (var i = 0; i < numData; i++){
            var newwidth = $('.chartAreaWrapper2').width() +60;
            $('.chartAreaWrapper2').width(newwidth);
        }
    }

    if (document.getElementById("tag_wise")) {
    var tag_wise = document.getElementById("tag_wise").getContext('2d');
        var tagChart = new Chart(tag_wise, {
            type: 'bar',
            data: {
                labels: JSON.parse(tag_wise.canvas.dataset.key),
                datasets: [{
                    data: JSON.parse(tag_wise.canvas.dataset.data),
                    backgroundColor: colors[0],
                    borderRadius: 15,
                    barThickness: 20,
                }]
            },
            maintainAspectRatio: false,
            responsive: true,
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'Tag name',
                            fontSize: 16,
                            fontColor: '#000',
                        },
                        barPercentage: 0.4,
                        categoryPercentage: 0.5
                    }], yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'Display Count',
                            fontSize: 16,
                            fontColor: '#000',
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 50
                        }
                    }]
                },
                animation: {
                    onComplete: function() {
                        var sourceCanvas = this.chart.canvas;

                        var copyWidth = this.scales['y-axis-0'].width - 10;

                        var copyHeight = this.scales['y-axis-0'].height + this.scales['y-axis-0'].top + 10;

                        var targetCtx = document.getElementById("myChartAxis").getContext("2d");

                        targetCtx.canvas.width = copyWidth;
                        targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight);
                    }
                }
            }
        });
        addData(5, tagChart);
        $("#tag_wise").click(function (event) {
            const points = tagChart.getElementsAtEventForMode(event, 'nearest', {intersect: true}, true);
            if (points.length > 0) {
                const stateName = tagChart.data.labels[points[0]._index];

                const url = `tag?tagwises=${stateName}`; // Replace with the appropriate URL format for your Rails app
                window.location.href = url;
            }
        })
    }

    function stateaddData(numData, chart){
        for (var i = 0; i < numData; i++){
            var newwidth = $('.statechartAreaWrapper2').width() +60;
            $('.statechartAreaWrapper2').width(newwidth);
        }
    }

    if (document.getElementById("state_wise_online")) {
    var state_wise_online = document.getElementById("state_wise_online").getContext('2d');
        var stateChart = new Chart(state_wise_online, {
            type: 'bar',
            data: {
                labels: JSON.parse(state_wise_online.canvas.dataset.key),
                datasets: [{
                    label: 'Online',
                    data: JSON.parse(state_wise_online.canvas.dataset.online),
                    backgroundColor: '#06D6A0',
                    barThickness: 20,
                },
                    {
                    label: 'Offline',
                    data: JSON.parse(state_wise_online.canvas.dataset.offline),
                    backgroundColor: '#26547C',
                        barThickness: 20,
                }]
            },
            maintainAspectRatio: false,
            responsive: true,
            options: {
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        fontColor: "#000080",
                    }
                },
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'State',
                            fontSize: 16,
                            fontColor: '#000',
                            barPercentage : 0.6,
                            categoryPercentage : 0.5,
                        },

                    }], yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'Display Count',
                            fontSize: 16,
                            fontColor: '#000',

                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 50
                        }
                    }]
                },
                animation: {
                    onComplete: function() {
                        var sourceCanvas = this.chart.canvas;
                        var copyWidth = this.scales['y-axis-0'].width - 10;
                        var copyHeight = this.scales['y-axis-0'].height + this.scales['y-axis-0'].top + 10;
                        var targetCtx = document.getElementById("stateaxis").getContext("2d");
                        targetCtx.canvas.width = copyWidth;
                        targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight);
                    }
                }
            }
        });
        stateaddData(5, state_wise_online);
    }

    $("#state_wise_online").click(function (event) {
        const points = stateChart.getElementsAtEventForMode(event, 'nearest', {intersect: true}, true);
        if (points.length > 0) {
            const stateName = stateChart.data.labels[points[0]._index];
            const url = `displays/status?states=${stateName}`; // Replace with the appropriate URL format for your Rails app
            window.location.href = url;
        }
    })

    function cityaddData(numData, chart){
        for (var i = 0; i < numData; i++){
            var newwidth = $('.citychartAreaWrapper2').width() +60;
            $('.citychartAreaWrapper2').width(newwidth);
        }
    }
    if (document.getElementById("city_wise_online")) {
    var city_wise_online = document.getElementById("city_wise_online").getContext('2d');

       var cityChart =  new Chart(city_wise_online, {
            type: 'bar',

            data: {
                labels: JSON.parse(city_wise_online.canvas.dataset.key),
                datasets: [{
                    label: 'Online',
                    data: JSON.parse(city_wise_online.canvas.dataset.online),
                    backgroundColor: '#06D6A0',
                    borderRadius: 15,
                    barThickness: 10,
                },
                    {
                        label: 'Offline',
                        data: JSON.parse(city_wise_online.canvas.dataset.offline),
                        backgroundColor: '#26547C',
                        borderRadius: 15,
                        barThickness: 10,
                    }]
            },
            maintainAspectRatio: false,
            responsive: true,
            options: {
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        fontColor: "#000080",
                    }
                },
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'City',
                            fontSize: 16,
                            fontColor: '#000',
                            barPercentage : 0.4,
                            categoryPercentage : 0.5
                        },
                        barPercentage: 1.0,
                        categoryPercentage: 0.5
                    }], yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'Display Count',
                            fontSize: 16,
                            fontColor: '#000',
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 50
                        }
                    }]
                },
                animation: {
                    onComplete: function() {
                        var sourceCanvas = this.chart.canvas;
                        console.log(sourceCanvas)
                        var copyWidth = this.scales['y-axis-0'].width - 10;
                        console.log(copyWidth)
                        var copyHeight = this.scales['y-axis-0'].height + this.scales['y-axis-0'].top + 10;
                        console.log(copyHeight)
                        var targetCtx = document.getElementById("cityaxis").getContext("2d");
                        console.log(targetCtx)
                        targetCtx.canvas.width = copyWidth;
                        targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth, copyHeight, 0, 0, copyWidth, copyHeight);
                    }
                }
            }
        });
        cityaddData(5, city_wise_online);
    }
    $("#city_wise_online").click(function (event) {
        const points = cityChart.getElementsAtEventForMode(event, 'nearest', {intersect: true}, true);
        if (points.length > 0) {
            const stateName = cityChart.data.labels[points[0]._index];
            const url = `displays/status?cities=${stateName}`; // Replace with the appropriate URL format for your Rails app
            window.location.href = url;
        }
    })
    if (document.getElementById("platform_wise_display")) {
    var platform_wise_display = document.getElementById("platform_wise_display").getContext('2d');
        var platformChart=new Chart(platform_wise_display, {
            type: 'bar',
            data: {
                labels: JSON.parse(platform_wise_display.canvas.dataset.key),
                datasets: [{
                    data: JSON.parse(platform_wise_display.canvas.dataset.data),
                    backgroundColor: colors[1],
                    barThickness: 20
                }]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'Platform',
                            fontSize: 16,
                            fontColor: '#000',
                        },
                        barPercentage: 0.4,
                        categoryPercentage: 0.5
                    }], yAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'Display Count',
                            fontSize: 16,
                            fontColor: '#000',
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 50
                        }
                    }]
                }
            }
        });
    }


    $("#platform_wise_display").click(function (event) {
        const points = platformChart.getElementsAtEventForMode(event, 'nearest', {intersect: true}, true);
        if (points.length > 0) {
            const stateName = platformChart.data.labels[points[0]._index];
            const url = `displays/status?ostype=${stateName}`; // Replace with the appropriate URL format for your Rails app
            window.location.href = url;
        }
    })

}



