import React, { useState, useEffect } from "react";
import PlaylistSidebar from './PlayListSidebar';
import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list'
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import { Select, MenuItem } from "@mui/material/";
import editIcon from "../../assets/images/icon/edit-button.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './EditSchedule';
import './schedule.scss';
import CommonModal from './CommonModal';
let overLapIds = [];
function Schedule(props) {
  const contentMgmKeys=props.content_mgm_keys
  const [contentData, setContentData] = useState([]);
  const [playListData, setPlayListData] = useState([]);
  const [schedulePopup, setSchedulePopup] = useState(false)
  const [popupEvent, setPopupEvent] = useState([])
  const [newaddId, setNewaddId] = useState('')
  const [calendarEvents, setCalendarEvents] = useState([])
  const [newPlaylistId, setPlaylistId] = useState('');
  const [newContentId, setContentId] = useState('');
  const [open, setOpen] = React.useState(false);
  const [timeDuration, setTimeDuration] = useState('00:30:00');
  const [scheduleName, setScheduleName] = useState(props.schedule.name);
  const [editBtn, setEditBtn] = useState(false);
  const addtriggerlist = '/v2/schedules/' + props.schedule.id + '/triggers';
  const [contentApproval, setContentApproval] = useState(false);
  const [showClosePopup,setShowClosePopup] = useState(false);
  const [confirmationData,setConfirmatioData] = useState('no');
  const [triggerCount, setTriggerCount] = useState(0)
  const [showOverLap,setShowOverLap] =  useState(false)
  const [oveLapSaveData,setOverLapSaveData] = useState({})
  const [overLapConfirmationData,setOverLapConfirmationData] = useState(' ')
  const [repeatOverLapConfirmationData,setRepeatOverLapConfirmationData] = useState(' ')
  const [repeatShowOverlap,setRepeatShowOverLap] = useState(false)
  const [processing,setProcessing] = useState(false)

  var IntervalID

  const handleChange = (e) => {
    setTimeDuration(e.target.value)
    if(e.target.value === '00:60:00'){
      const slots = document.querySelectorAll('.fc-timegrid-slot');
        slots.forEach(slot => {
          slot.style.height = '2.58rem';
        });
    }else{
      const slots = document.querySelectorAll('.fc-timegrid-slot');
      slots.forEach(slot => {
        slot.style.height = '1.5rem';
      });
    }
  }
  const deleteScheduleLock=(cb)=>{
    axios
        .delete(`/v1/schedules/` + props.schedule.id + `/lock.json`, {
          headers: {
            contentType: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          console.log('api response  details is ====', res)
          cb()
        })
        .catch(error => {
          console.log(error)
          cb()
        });
  }
  const updateScheduleLock=()=>{
    axios
        .put(`/v1/schedules/` + props.schedule.id + `/lock.json`, {},{
          headers: {
            contentType: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          console.log('api response  details is ====', res)

        })
        .catch(error => {
          console.log(error)
        });
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const nameEdit = () => {
    document.getElementById('nameEdit').classList.remove("input-span")
  }
  const getCalenderData = () => {
    let scheduleData = {};
    let newArray = [];
    setNewaddId('');
    axios
        .get(`/v2/schedules/` + props.schedule.id + `.json`, {
          headers: {
            contentType: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          res.data.items.map((file, index) => {
            if (file.trigger_id === null) {
              scheduleData = {
                content_id: file.content_id,
                playlist_id: file.playlist_id,
                id: file.id,
                startRecur: file.start_date,
                endRecur: moment(new Date(
                    new Date(file.end_date).setDate(new Date(file.end_date).getDate() + 1))).format('YYYY-MM-DD'),
                title: file.content_name != null ? file.content_name : file.playlist_name,
                startTime: file.start_time_str,
                duration: `${file.duration}`,
                repeat_type: file.repeat_type,
                color: ((file.playlist_id === -1) || file.playlist_id === null) ? '#778899': '#4F98A7',
                type: (file.playlist_id === -1 || file.playlist_id === null) ? 'content' : 'playlist',
                endDate: file.end_date,
                startDate: file.start_date,
                endTime: findEndTime(file.start_time_str, file.duration),
                // updateAt:moment(new Date(file.updated_at)).format('YYYY-MM-DD')
              }
              if(scheduleData.duration === '1440'){
                scheduleData.allDay = true;
                delete scheduleData.endTime;
              }
              newArray.push(scheduleData);
            }
          });
          res.data.active_trigger_count && setTriggerCount(res.data.active_trigger_count);
          setCalendarEvents(newArray);
        })
        .catch(error => {
          console.log(error)
        })
        setProcessing(false);
  }
  useEffect(() => {
    setContentApproval(appConfig.useContentApproval);
    IntervalID=setInterval(updateScheduleLock,180000)
  }, []);
  useEffect(() => {
    {
      getCalenderData();
    }
  }, [props.schedule.items]);
  useEffect(() => {
    axios
        .get(`/v2/contents.json`, {
          headers: {
            contentType: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          let data = [res.data]
          setContentData(data);
        })
        .catch(error => {
          console.log(error)
        })
  }, []);

  useEffect(() => {
    axios
        .get(`/v2/playlists.json`, {
          headers: {
            contentType: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          let data = [res.data]
          setPlayListData(data);
        })
        .catch(error => {
          console.log(error)
        })
  }, []);

  useEffect(() => {
    axios
        .get(`/v1/schedules/` + props.schedule.id + `/lock.json`, {
          headers: {
            contentType: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          console.log('api response for playlists details is ====', res)
        })
        .catch(error => {
          console.log(error)
        });
    let data = {}
    axios
        .post(`/v1/schedules/` + props.schedule.id + `/lock.json`, data, {
          headers: {
            contentType: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          console.log('api response for playlists details is ====', res)
        })
        .catch(error => {
          console.log(error)
        })
  }, []);

  const [state, setState] = useState({
    weekendsVisible: true
  });

  // load external events
  useEffect(() => {
    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        let id = eventEl.dataset.id;
        let title = eventEl.getAttribute("title");
        let color = eventEl.dataset.color;
        let type = eventEl.dataset.type;

        return {
          id: id,
          title: title,
          color: color,
          type: type,
          create: true
        };
      }
    });
  }, []);

  const repeateEvent = (data) => {
    setProcessing(true)
    calendarEvents.splice(calendarEvents.findIndex(a => a.content_id === data.content_i), 1)
      var endDateData = new Date(
          new Date(data.endDate).setDate(new Date(data.endDate).getDate() + 1));
    let newData = {
      id: data.id,
      content_id: data.content_id,
      playlist_id: data.playlist_id,
      startTime: data.startTime,
      endTime: data.endTime,
      startRecur: data.startDate,
      endRecur: moment(endDateData).format('YYYY-MM-DD'),
      title: data.title,
      repeat_type: data.repeat_type?'daily':'none',
      endDate: data.endDate,
      startDate: data.startDate,
    }
    let duration = timeDiff(data.startTime, data.endTime)
    if(duration === '00'){
      duration = 1440
    }
    const newEvent = {
      id: data.id,
      content_id: data.content_id,
      playlist_id: data.playlist_id,
      start_date: data.startDate,
      end_date: data.endDate,
      start_time_str: moment(data.startTime, "HH:mm:ss").format("HH:mm:ss"),
      duration: duration,
      repeat_type: data.repeat_type?'daily':'none',
    };
    let saveData = {};
    saveData = {
      item: newEvent
    }
    setCalendarEvents(calendarEvents.concat(newData));
    checkOverLapEvent(data,saveData,'drag')
    // save(saveData, 'drag');
  }
  const timeDiff = (start, end) => {
    start = start.split(":");
    end = end.split(":");
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    if (diff < 0) {
      return false;
    }else{
      var hours = Math.floor(diff / 1000 / 60 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      var minutes = Math.floor(diff / 1000 / 60);
      return (minutes < 9 ? "0" : "") + minutes;
    }
  }
  const checkOverLapEvent = (event,saveData,type)=>{
    let overlap =  calendarEvents.map((data)=>{
      if((saveData?.item.id != data?.id) && (Date.parse(saveData?.item?.start_date) <=  Date.parse(data?.endDate) && Date.parse(data?.startDate) <= Date.parse((saveData?.item?.end_date))) && saveData?.item?.duration == data?.duration && saveData?.item?.start_time_str === data?.startTime && data?.repeat_type === 'daily') {
        setOverLapSaveData({
          saveData,type,event,
        })
        setRepeatShowOverLap(true)
        // if (!overLapIds.includes(data.id)){
        //   overLapIds.push(data.id);
        // }
        return true;
      }
      if((saveData?.item.id != data?.id) && (Date.parse(saveData?.item?.start_date) <=  Date.parse(data?.endDate) && Date.parse(data?.startDate) <= Date.parse((saveData?.item?.end_date))) && saveData?.item?.duration == data?.duration && saveData?.item?.start_time_str === data?.startTime && data?.repeat_type !== 'daily') {
        setOverLapSaveData({
          saveData,type,event,
        })
        setShowOverLap(true)
        if (!overLapIds.includes(data.id)){
          overLapIds.push(data.id);
        }
        return true;
      }
    })
    if(!overlap.includes(true)){
      save(saveData,type)
    }
  }

  const handleEventReceive = (eventInfo, type) => {
    const startDateTime = moment(eventInfo.event.start).format('YYYY-MM-DD HH:mm:ss A');
    const startDateTimeArray = startDateTime.split(" ");
    const endDateTime = moment(eventInfo.event.end).format('YYYY-MM-DD HH:mm:ss A');
    const endDateTimeArray = endDateTime.split(" ");
    let duration = 0;
    if(startDateTimeArray[1] === '00:00:00' && endDateTimeArray[1] === '00:00:00'){
      duration = 1440;
      endDateTimeArray[0]= startDateTimeArray[0]
    }else if(startDateTimeArray[1] > endDateTimeArray[1]){
      getCalenderData();
      return;
    } else{
      duration = timeDiff(startDateTimeArray[1], endDateTimeArray[1])
    }
    if(!duration){
      getCalenderData();
      return false;
    }
    setProcessing(true)
     const newEvent = {
      content_id: type === 'drag' ? eventInfo.event._def.extendedProps.content_id
          : eventInfo.draggedEl.getAttribute("type") === 'content' ? eventInfo.draggedEl.getAttribute("data-id") : -1,
      playlist_id: type === 'drag' ? eventInfo.event._def.extendedProps.playlist_id
          : eventInfo.draggedEl.getAttribute("type") === 'playlist' ? eventInfo.draggedEl.getAttribute("data-id") : -1,
      start_date: startDateTimeArray[0],
      end_date: endDateTimeArray[0],
      start_time_str: startDateTimeArray[1],
      duration: duration,
      repeat_type: "none",
      allDay : true,
      id: eventInfo.event._def.publicId
    };
    let saveData = {};
    saveData = {
      item: newEvent
    }
    checkOverLapEvent(eventInfo,saveData, type);
  };
  const save = (data, type) => {
    if(data?.item?.allDay){
      delete data?.item?.allDay
    }
    let itemId = '';
    if (type === 'drag') {
      itemId = newaddId !== '' ? newaddId : data?.item?.id;
    }
    if (data?.item?.id) {
      delete data?.item?.id;
    }
    let url = type === 'drag' ? `/v1/schedules/` + props.schedule.id + `/items/` + itemId + `.json`
        : `/v1/schedules/` + props.schedule.id + `/items.json`;
    if (type === 'drag') {
      axios
          .put(url, data, {
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "X-CSRF-Token": document.querySelector(
                  "meta[name='csrf-token']"
              ).content,
            },
          })
          .then(res => {
            console.log('post response==', res)
            getCalenderData();
            notify('Schedule updated successfully', 'success');
          })
          .catch(error => {
            console.log('error',error)
          })
    } else {
      axios
          .post(url, data, {
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "X-CSRF-Token": document.querySelector(
                  "meta[name='csrf-token']"
              ).content,
            },
          })
          .then(res => {
            setNewaddId(res.data.id);
            setContentId(res.data.content_id);
            setPlaylistId(res.data.playlist_id);
            getCalenderData();
            notify('Schedule saved successfully', 'success');
          })
          .catch(error => {
            console.log(error)
            notify('Getting error while saving.', 'error');
          })
    }
  }

  const deleteEvent = (id, event) => {
    id = id === undefined ? newaddId : id;
    axios
        .delete(`/v1/schedules/` + props.schedule.id + `/items/` + id + `.json`, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          console.log('post response==', res);
          event.event.remove();
          notify('Schedule deleted successfully', 'success');
          getCalenderData();
        })
        .catch(error => {
          console.log(error)
          notify('Getting error while deleting.', 'error');
        })
  }
  const showEditSchedulePopup = (event) => {
    if(!processing){
      setSchedulePopup((current) => !current);
      setPopupEvent(event);
    }
  }

  function addMinutesToTime(time, minutes) {
    let [hours, mins] = time.split(":").map(Number);
    hours += Math.floor((mins + minutes) / 60);
    mins = (mins + minutes) % 60;
    hours = hours % 24;
    return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}:00`;
  }

  function findEndTime(startTime, duration) {
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const totalMinutes = startHours * 60 + startMinutes + duration;
    const endTime = addMinutesToTime(startTime, duration);
    return endTime;
  }
  const saveScheduleName = (e) => {
    if (scheduleName !== e.target.value) {
      setEditBtn(true)
    }
    setScheduleName(e.target.value);
  }
  const updateName = () => {
    if (scheduleName === '') {
      notify('Schedule name could not be null', 'error');
      return;
    }

    let pattern = /^[a-z\d\-_\s]+$/i;
    let isValid = pattern.test(scheduleName);
    if (!isValid) {
      notify('Only letters, number and _ allowed', 'error');
      return;
    }
    let url = `/v2/schedules/` + props.schedule.id + `.json`;
    let saveData = {};
    saveData = {
      'v2_schedule':{
        'name': scheduleName
      }
    }
    axios
        .put(url, saveData, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          console.log('post response==', res)
          notify('Schedule name updated successfully.', 'success');
        })
        .catch(error => {
          var errormessage=error && error?.response?.data?.message?.name?.length ? error.response.data.message.name[0] :'Getting error while updating the name.'
          notify(errormessage, 'error');
          if(error && error?.response?.data?.name) setScheduleName(error.response.data.name)
        })
    setEditBtn(false);
    document.getElementById('nameEdit').classList.add("input-span")
  }
  const notify = (msg, type) => {
    switch (type) {
      case 'success':
        toast.success(msg);
        break;
      case 'error':
        toast.error(msg);
        break;
    }
  }
  const closeSchedule = () => {
    if(calendarEvents.length === 0 && newaddId === ''){
      setShowClosePopup(true)
    }else{
      if (props.schedule.parent_folder_id !== null) {
        deleteScheduleLock(function(){
          window.location = '/v2/schedules?folder_id=' + props.schedule.parent_folder_id;
          clearInterval(IntervalID)
        })

      } else {
        deleteScheduleLock(function(){
          clearInterval(IntervalID)
          window.location = '/v2/schedules';
        })
      }
    }
  }
  useEffect(() => {
    if (confirmationData === 'yes') {
      if (props.schedule.parent_folder_id !== null) {
        deleteScheduleLock(function(){
          clearInterval(IntervalID)
          window.location = '/v2/schedules?folder_id=' + props.schedule.parent_folder_id;
        })
      } else{
        clearInterval(IntervalID)
        deleteScheduleLock(function(){
          window.location = '/v2/schedules';
        })
      }
    }
  }, [confirmationData]);
  useEffect(() => {
    if(!showOverLap){
      setOverLapConfirmationData(false);
    }
  }, [showOverLap]);
  useEffect(() => {
    if(!repeatShowOverlap){
      setRepeatOverLapConfirmationData(false);
    }
  }, [repeatShowOverlap]);

  useEffect(() => {
    if (repeatOverLapConfirmationData === 'yes') {
      save(oveLapSaveData?.saveData,oveLapSaveData?.type)
    }else if(repeatOverLapConfirmationData === 'no'){
      oveLapSaveData?.event?.event?.remove();
      getCalenderData();
    }
  }, [repeatOverLapConfirmationData]);

  useEffect(() => {
    if (overLapConfirmationData === 'yes') {
      deleteOverLapSchedule(overLapIds)
      save(oveLapSaveData.saveData,oveLapSaveData.type)
    }else if(overLapConfirmationData === 'no'){
      oveLapSaveData?.event?.event?.remove();
      getCalenderData();
    }
  }, [overLapConfirmationData]);
  const deleteOverLapSchedule = (ids) =>{
    for(let i= 0; i< ids.length; i++) {
    axios
        .delete(`/v1/schedules/` + props.schedule.id + `/items/` + ids[i] + `.json`, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.querySelector(
                "meta[name='csrf-token']"
            ).content,
          },
        })
        .then(res => {
          getCalenderData();
        })
        .catch(error => {
          console.log(error)
          notify('Getting error while deleting.', 'error');
        })
    }
    overLapIds = [];
  }
  return (
      <>
        <div className="scheduleContainer">
          <ToastContainer />
          {showClosePopup && <CommonModal setShowPopup={setShowClosePopup} show={true}
                                           title={'Confirmation'} body='There is no schedule assigned , are you sure you want to exit?'
                                           setConfirmatioData={setConfirmatioData} />}
          {showOverLap && <CommonModal setShowPopup={setShowOverLap} show={true}
                                          title={'Confirmation'} body='This schedule will replace the existing schedule. Do you want to continue?'
                                          setConfirmatioData={setOverLapConfirmationData}/>}
          {repeatShowOverlap && <CommonModal setShowPopup={setRepeatShowOverLap} show={true}
                                       title={'Confirmation'} body='This schedule will take priority over the existing schedule. Do you want to continue?'
                                       setConfirmatioData={setRepeatOverLapConfirmationData}/>}
          <div className="col-sm-12 col-md-12 col-lg-12 schedule-width">
            <div className="row">
              <div className="col-sm-2 col-md-2 col-lg-2" id="external-events">
                {contentData.length > 0 && <PlaylistSidebar contentApproval={contentApproval} handleOnDragSidebarEnd=''
                                                            playListData={playListData} contentData={contentData} contentMgmKeys={contentMgmKeys} />}
              </div>
              <div className="col-sm-10 col-md-10 col-lg-10">
                <div className="main-content-schedule">
                  <Modal popupEvent={popupEvent} saveSchedulePopup={repeateEvent}
                         setSchedulePopup={setSchedulePopup} show={schedulePopup}
                         deleteEvent={deleteEvent} title={'Edit Schedule Details'} body={'test'} scheduleId={props.schedule.id}
                         newPlaylistId={newPlaylistId} newContentId={newContentId}/>

                  <div className="form-group row">
                    <div className="col-5">
                      <div>
                    <span className="input-icon" onClick={nameEdit}><i className="fas fa-edit"></i>
                    </span><input className='input-span' id="nameEdit" type="text" contentEditable="true"
                                  value={scheduleName} onChange={saveScheduleName}
                                  onFocus={() => { document.getElementById('nameEdit').classList.remove("input-span") }} maxLength={60} />
                        {editBtn && <button className="btn btn-add update" onClick={updateName}>Update</button>}
                      </div>
                    </div>
                    <div className="col-3">

                      <Select
                          sx={{ width: '10rem',height:'2.2rem'}}
                          id="demo-controlled-open-select"
                          open={open}
                          onClose={handleClose}
                          onOpen={handleOpen}
                          value={timeDuration}
                          onChange={handleChange}
                          placeholder="Select a time duration"
                          displayEmpty
                      >
                        <InputLabel className="drop_down_label">Select a time duration</InputLabel>
                        <MenuItem value="00:60:00">1 hour duration</MenuItem>
                        <MenuItem value="00:30:00">30 min duration</MenuItem>
                        <MenuItem value="00:15:00">15 min duration</MenuItem>
                        <MenuItem value="00:10:00">10 min duration</MenuItem>
                        <MenuItem value="00:05:00">05 min duration</MenuItem>
                      </Select>
                    </div>
                    <div className="col-4 margin-bottom">
                      <div className="schedule-btn">
                        <span className="triggertag" title="Number of triggers in this schedule">Trigger: {triggerCount}s</span>
                        <a className="btn btn-save-button trigger-btn" href={addtriggerlist}>
                          <span>Add Trigger </span>

                        </a>
                        <button className="btn btn-save-button trigger-btn btn-close-button" title='close'
                                onClick={closeSchedule}>Close
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* dayGridMonth,timeGridWeek,timeGridDay  to add month,week and day wise calender*/}
                  <div className="App">
                    <div style={{ float: "left", width: "100%" }}>
                      <FullCalendar
                          plugins={[dayGridPlugin,timeGridPlugin, interactionPlugin, listPlugin]}
                          headerToolbar={{
                            left: "prev",
                            right: "today,next",
                            center: "title"
                          }}
                          initialView="timeGridWeek"
                          editable={true}
                          selectMirror={false}
                          dayMaxEvents={false}
                          weekends={state.weekendsVisible}
                          events={calendarEvents}
                          droppable={true}
                          eventReceive={(event) => handleEventReceive(event, '')}
                          nowIndicator={true}
                          eventDrop={(event) => handleEventReceive(event, 'drag')}
                          slotDuration={timeDuration}
                          forceEventDuration={true}
                          eventClick={(event) => showEditSchedulePopup(event)}
                          allDaySlot={true}
                          timeFormat='h:mm A'
                          defaultTimedEventDuration={timeDuration}
                          eventResize = {(event) => handleEventReceive(event,'drag')}
                          eventTimeFormat={{
                            hour: "numeric",
                            minute: "2-digit",
                            meridiem: "short",
                          }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}
export default Schedule;

